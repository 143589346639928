// import type { RemoveRoutePinMutation } from '$lib/queries/generated/RemoveRoutePin';
import type {
	CacheExchangeOpts,
	DataFields,
	ResolveInfo,
	Variables,
} from '@urql/exchange-graphcache'
import {
	duplicateTripHandler,
	insertMediaHandler,
	insertTripHandler,
	makePurchaseHandler,
	publishListingHandler,
	unpublishListingHandler,
} from './cache/trips'
import {
	addToListHandler,
	deleteBlockHandler,
	deleteFromItineraryQueryCache,
	insertCustomRegionBlockHandler,
	insertDayBlockHandler,
	insertDestinationBlockHandler,
	insertHighlightBlockHandler,
	insertListBlockHandler,
	insertPinBlockHandler,
	insertPlaceBlockHandler,
	insertRequirementBlockHandler,
	insertRootBlockHandler,
	insertSourceBlockHandler,
	removeFromListHandler,
	updateBlockPositionsHandler,
	upsertRouteBlockHandler,
} from './cache/blocks'
import { ProfileFieldsFragmentDoc } from '$lib/queries/fragments/generated/ProfileFields'
import { fetchCouponCodesHandler } from './cache/store'
import { relayPagination } from '@urql/exchange-graphcache/extras'
// import { insertRoutePinHandler, removeRoutePinHandler } from './cache/pinning';
// import { insertReservationHandler } from './cache/reservations';
// import type { MutationRemoveRoutePinArgs } from './types.d';

// returning null embeds the data directly into the cache without normalization
export const embed = () => null

function dateTimeResolver(parent: DataFields, args: Variables, _: any, info: ResolveInfo) {
	return parent[info.fieldName] != null ? new Date(parent[info.fieldName] as string) : null
}

export const normalizedCacheConfig: Partial<CacheExchangeOpts> = {
	// schema: schema,
	keys: {
		// PinnedRoute: (r) => r.path as string,
		// IntercomUserData: (r) => r.user_id as string,
		GooglePlaceResult: (r) => r.placeId as string,
		GooglePhoto: (r) => r.googleName as string,
		Venue: (r) => r.placeId as string,
		Location: embed,
	},
	optimistic: {
		updateProfile: (args, cache, info) => {
			const profileFragment = cache.readFragment(ProfileFieldsFragmentDoc, { id: args.id })

			if (!profileFragment) return null
			return {
				...profileFragment,
				bio: args.bio,
			}
		},
		deleteTrip: (args, cache, info) => {
			return { id: args.id }
		},
		deleteBlock: (args, cache, info) => {
			return { parentBlock: null, trip: null, deletedBlockIds: [args?.id] }
		},
		// updateBlockPositions: (args, cache, info) => {
		// 	return []
		// },
	},

	updates: {
		Mutation: {
			// deleteTrip: deleteTripHandler,
			deleteTrip(_result, args, cache, _info) {
				cache.invalidate({
					__typename: 'Trip',
					id: args.id as string,
				})
			},
			insertRootBlock: insertRootBlockHandler,
			insertTrip: insertTripHandler,
			insertMedia: insertMediaHandler,
			duplicateTrip: duplicateTripHandler,
			makePurchase: makePurchaseHandler,
			publishListing: publishListingHandler,
			unpublishListing: unpublishListingHandler,
			addToList: addToListHandler,
			removeFromList: removeFromListHandler,
			insertPinBlock: insertPinBlockHandler,
			insertListBlock: insertListBlockHandler,
			insertPlaceBlock: insertPlaceBlockHandler,
			insertSourceBlock: insertSourceBlockHandler,
			insertDestinationBlock: insertDestinationBlockHandler,
			insertCustomRegionBlock: insertCustomRegionBlockHandler,
			insertHighlightBlock: insertHighlightBlockHandler,
			insertDayBlock: insertDayBlockHandler,
			insertRequirementBlock: insertRequirementBlockHandler,
			upsertRouteBlock: upsertRouteBlockHandler,
			deleteBlock: deleteBlockHandler,
			updateBlockPositions: updateBlockPositionsHandler,
			createCoupon: fetchCouponCodesHandler,
		},
	},
	resolvers: {
		Query: {
			itineraryConnection: relayPagination(),
		},
	},
}
